import { Box, Checkbox, Grid, ListItemText, MenuItem, Select, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, Legend } from 'recharts'
import { Colors } from '../../../common/PanaTheme'
import { useTranslation } from 'react-i18next'

const RATE = 23000
const COLORS = [
  '#0048d3',
  '#ff812b'
]
const avgKey = 'Line Avg'
export default React.memo(function ChartView ({ filterResult, allCatColumn }: { filterResult: any[], allCatColumn: string[] }): JSX.Element {
  const { t } = useTranslation()
  const [listCategories, setListCategories] = useState<string[]>(allCatColumn.slice(0, 2))
  const getData = (rows: any): any => {
    const keys: string[] = []
    const data = rows.map((r: any): any => {
      const row: any = {}
      r.columns.forEach((column: any, index: number) => {
        const key = `${t(column.category_column.title as string) ?? ''} (${t(column.category_column.unit as string) ?? ''})`
        if (!keys.includes(key) && listCategories.includes(column.category_column.title)) {
          keys.push(key)
        }
        if (listCategories.includes(column.category_column.title)) {
          row[key] = (column.category_column.unit === 'USD') ? Number(column.value) * RATE : Number(column.value)
        }
      })
      let total = 0
      Object.values(row).forEach((item) => {
        total = total + Number(item)
      })
      row[avgKey] = total / Object.values(row).length
      if (!keys.includes(avgKey)) {
        keys.push(avgKey)
      }
      row.name = r.name
      return row
    })
    console.log(data)

    return { data, keys }
  }
  return (
    <Box>
      {allCatColumn.length > 0 && <Box className="projectCard">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box mb={1}>{t('Select categories to show on chart')}</Box>
            <Select size='small'
              fullWidth
              multiple
              value={listCategories}
              onChange={(event) => {
                setListCategories(event.target.value as any[])
              }}
              renderValue={(selected) => {
                if (selected.length > 0) {
                  return selected.join(', ')
                }
                return t('Select') ?? ''
              }}
            >
              {allCatColumn.map((cat, index) =>
                <MenuItem key={index} value={cat}>
                  <Checkbox checked={listCategories.includes(cat)} />
                  <ListItemText primary={cat} />
                </MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>
      </Box>
      }
      {filterResult.map((group: any): JSX.Element => {
        return <Box mt={4} className="projectCard" key={group.key}>
          <Box className="tableTools">
            <Typography variant='h4'>{group.key}</Typography>
          </Box>
          <Box py={8} overflow="auto">
            <Box width={getData(group.rows).keys.length * 30 * getData(group.rows).data.length} minWidth="100%">
              <ResponsiveContainer width="100%" height={500}>
                <ComposedChart
                  data={getData(group.rows).data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid stroke={Colors.gray200} />
                  <XAxis dataKey="name" stroke={Colors.black} />
                  <YAxis stroke={Colors.black} />
                  <Tooltip />
                  <Legend />
                  {
                    getData(group.rows).keys.filter((key: string) => key !== avgKey).map((key: string, index: number): JSX.Element => {
                      return <Bar barSize={50} key={index} dataKey={key} fill={COLORS[index % 2]} />
                    })
                  }
                  <Line type="monotone" dataKey={avgKey} stroke="#30a14e" strokeWidth={3} />
                </ComposedChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </Box>
      })}
    </Box>
  )
})

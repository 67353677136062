import { Avatar, Box, Button, Typography } from '@mui/material'
import { StyledDataGrid } from '../../common/components'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useParams, Link as LLink } from 'react-router-dom'
import axios from 'axios'
import { type GridColDef } from '@mui/x-data-grid'
import { showDate } from '../../common/time'
import React, { useState } from 'react'
import { ApproveModal, MarkingModal, WinningModal } from './components'
import { ProjectApi, type User } from '../../common/services'
import localStorage from '../../common/localStorage'
import { stringAvatar } from '../../common/helper'
import moment from 'moment'

const isHaveWin = (data?: any[]): boolean => {
  return (data != null) ? data.findIndex(a => a.is_win) > -1 : false
}

export default function ProjectSelection (): JSX.Element {
  const { t } = useTranslation()
  const user: User = localStorage.getItem('USER')
  const [openMarking, setOpenMarking] = useState<any>({
    visible: false,
    row: null
  })
  const [openWinning, setOpenWinning] = useState<any>({
    visible: false,
    user: null
  })
  const [openApprove, setOpenApprove] = useState<any>({
    visible: false,
    type: 'approve'
  })
  const { id } = useParams()
  const { data, isLoading, isFetching, refetch } = useQuery(['/getSubmitData/projects', id], async () => {
    const { data } = await axios.get(`/getSubmitData/projects/${id ?? 0}`)
    return data
  })
  const projectQuery = useQuery(['/projects', id], async () => {
    const { data } = await new ProjectApi().showProject(id ?? '0')
    return data
  })
  const saveMarking = async (values: any): Promise<void> => {
    try {
      await axios.post('/marking/projects', {
        project_id: id,
        vendor_id: openMarking.row.user.id,
        ...values
      })
      void refetch()
      void projectQuery.refetch()
    } catch (ex: any) {
      alert(ex.message)
    }
  }
  const saveWinning = async (values: any): Promise<void> => {
    try {
      await axios.post('/winning/projects', {
        project_id: id,
        vendor_id: openWinning.user.id,
        ...values
      })
      void refetch()
      void projectQuery.refetch()
    } catch (ex: any) {
      alert(ex.message)
    }
  }

  const [isApproving, setIsApproving] = useState(false)
  const approve = async (values: any): Promise<void> => {
    try {
      setIsApproving(true)
      await axios.post('/approve/projects', {
        project_id: id,
        type: openApprove.type,
        ...values
      })
      void refetch()
    } catch (ex: any) {
      alert(ex.message)
    } finally {
      setIsApproving(false)
    }
  }
  const columns: Array<GridColDef<any>> = [
    {
      field: 'name',
      headerName: 'Vendor',
      width: 250,
      renderCell: ({ row }) => {
        return <Box display="flex">
          <Avatar alt={row.user.name} src={row.user.avatar} {...stringAvatar(row.user.name)} />
          <Box ml={3}>
            <Typography variant='body1' fontWeight={500}>{row.user.name}</Typography>
            <Typography variant='caption'>{row.user.title}</Typography>
          </Box>
        </Box>
      }
    },
    {
      field: 'content',
      headerName: 'Content',
      flex: 1,
      renderCell: ({ row }) => {
        return <Box>
          <Typography>{row.content}</Typography>
          {/* {row?.file !== '' && row?.file !== null && <Link to={row?.file} target='_blank'>Download brief</Link>} */}
        </Box>
      }
    },
    {
      field: 'auto_point',
      headerName: 'Price point',
      width: 120
    },
    // {
    //   field: 'point',
    //   headerName: 'Total Point',
    //   width: 120,
    //   renderCell: ({ row }) => {
    //     if (row.point === null && row.point_raw === null && user.id === projectQuery.data?.user_id && !isHaveWin(data)) {
    //       return <Button size='small' onClick={() => {
    //         setOpenMarking({
    //           visible: true,
    //           row
    //         })
    //       }}>
    //         {t('Mark')}
    //       </Button>
    //     } else {
    //       return <Typography>{row.point}</Typography>
    //     }
    //   }
    // },
    {
      field: 'created_at',
      headerName: 'Submitted at',
      width: 200,
      valueGetter: ({ row }) => {
        return showDate(row.created_at)
      }
    },
    {
      field: 'is_win',
      headerName: 'Win status',
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => {
        if (isHaveWin(data)) {
          return <Box>
            <Typography>{row.is_win === 1 ? 'WIN' : 'LOSE'}</Typography>
            <Typography variant='body2'>{row.is_approve === 1 ? 'Approved' : 'Pending'}  </Typography>
          </Box>
        } else {
          return projectQuery.data?.user_id === user.id
            ? <Button size='small' onClick={() => {
              setOpenWinning({
                visible: true,
                user: row.user
              })
            }}>
              {t('SELECT WINNER')}
            </Button>
            : <></>
        }
      }
    }
  ]
  if (moment().unix() <= moment(projectQuery.data?.ended_at).unix()) {
    return (<Box className="projectCard">
      <Typography variant='h4' mb={2}>{t('Deadline has not passed!')}</Typography>
      <Typography>
        Oops! It looks like the deadline for submitting proposals has not passed. We appreciate your interest, but we are no longer accepting to view project report at this time. If you have any questions or need further assistance, please feel free to contact our team. Thank you for your understanding!
      </Typography>
    </Box>)
  }

  return (
    <Box>
      <Typography variant='h1' mb={5}>#00{projectQuery.data?.id} {projectQuery.data?.name} - Selection</Typography>
      <Box sx={{ mt: 4 }} className="projectCard">
        <Box px={3} pt={4} mb={2} display="flex" justifyContent="space-between">
          <LLink to={`/projects/${id ?? ''}`}>
            <Button variant="outlined">{t('Back to project')}</Button>
          </LLink>
          {isHaveWin(data) && (projectQuery.data as any)?.approve_id === user.id && projectQuery.data?.status === 'WaitingForApprove' &&
            <Box display="flex" gap={4}>
              <Button variant="outlined" disabled={isApproving} onClick={() => {
                setOpenApprove({
                  visible: true,
                  type: 'reject'
                })
              }}>{t('Reject')}</Button>
              <Button variant="contained" disabled={isApproving} onClick={() => {
                setOpenApprove({
                  visible: true,
                  type: 'approve'
                })
              }}>{t('Approve')}</Button>
            </Box>
          }
        </Box>
        <Box>
          <StyledDataGrid
            autoHeight
            rowHeight={80}
            loading={isLoading || isFetching}
            rows={data ?? []}
            rowCount={data?.length ?? 0}
            columns={columns}
          />
        </Box>
        {(Boolean(openMarking.visible)) &&
          <MarkingModal row={openMarking.row} open={openMarking.visible} onClose={(value) => {
            if (value != null) {
              void saveMarking(value)
            }
            setOpenMarking({
              visible: false,
              row: null
            })
          }} />
        }
        {(Boolean(openWinning.visible)) &&
          <WinningModal user={openWinning.user} open={openWinning.visible} onClose={(value) => {
            if (value != null) {
              void saveWinning(value)
            }
            setOpenWinning({
              visible: false,
              user: null
            })
          }} />
        }
        {(Boolean(openApprove.visible)) &&
          <ApproveModal type={openApprove.type} open={openApprove.visible} onClose={(value) => {
            if (value != null) {
              void approve(value)
            }
            setOpenApprove({
              visible: false,
              type: null
            })
          }} />
        }

      </Box>
    </Box>
  )
}

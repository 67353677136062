export function stringToColor (string: string): string {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export function stringAvatar (name: string): any {
  return {
    sx: {
      bgcolor: stringToColor(name),
      borderRadius: 4
    },
    children: `${name.split(' ')[0][0]}`
  }
}
export function arrayToTree (arr: any[], parentId = 0): any[] {
  if (arr.length === 0) {
    return []
  }
  let pId = parentId
  if (parentId === -1) {
    pId = arr[0].parent_id
  }
  return arr
    .filter((item) => item.parent_id === pId)
    .map((child) => ({ ...child, children: arrayToTree(arr, child.id) }))
}

export const formatter = new Intl.NumberFormat()

/**
 * This function is same as PHP's nl2br() with default parameters.
 *
 * @param {string} str Input text
 * @param {boolean} replaceMode Use replace instead of insert
 * @param {boolean} isXhtml Use XHTML
 * @return {string} Filtered text
 */
export function nl2br (
  str?: string,
  replaceMode?: boolean,
  isXhtml?: boolean
): string {
  const breakTag = isXhtml === true ? '<br />' : '<br>'
  const replaceStr =
    replaceMode === true ? '$1' + breakTag : '$1' + breakTag + '$2'
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
}

import globalAxios from 'axios'
import localStorage from './localStorage'
import { type LoginResponse } from './services'

globalAxios.defaults.baseURL = process.env.REACT_APP_API_URL
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const requestHandler = async (request: any) => {
  const loginStorage: LoginResponse = await localStorage.getItem('LOGIN')
  if (loginStorage !== null) {
    request.headers.Authorization = `Bearer ${loginStorage.access_token}`
  }
  return request
}

globalAxios.interceptors.request.use(
  async (request) => await requestHandler(request),
  async (requestError) => {
    return await Promise.reject(requestError)
  }
)

globalAxios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

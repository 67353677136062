import React from 'react'
import ReactDOM from 'react-dom/client'
import Main from './pages/main'
import reportWebVitals from './reportWebVitals'

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import './scss/style.scss'
import MMMTheme from './common/PanaTheme'
import { Login, ProjectCompare, ProjectSelection, ProjectDetail, Projects, Register, ForgotPassword, ProjectForm, ProjectSubmit, Users, UserDetail, ProjectPreview, UserProfile } from './pages/index'
import './common/api'
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ToastContainer } from 'react-toastify'

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: '/',
        element: <Projects />
      },
      {
        path: '/profile',
        element: <UserProfile />
      },
      {
        path: '/users',
        element: <Users />
      },
      {
        path: '/users/:id',
        element: <UserDetail />
      },
      {
        path: '/new/project',
        element: <ProjectForm />
      },
      {
        path: '/projects/:id',
        element: <ProjectDetail />
      },
      {
        path: '/projects/:id/submit',
        element: <ProjectSubmit />
      },
      {
        path: '/projects/:id/preview',
        element: <ProjectPreview />
      },
      {
        path: '/projects/:id/compare',
        element: <ProjectCompare />
      },
      {
        path: '/projects/:id/selection',
        element: <ProjectSelection />
      }
    ]
  }
])

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={MMMTheme}>
          <RouterProvider router={router} />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </ThemeProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

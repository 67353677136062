import React, { useState } from 'react'
import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { FormValiationMessages } from '../../common/components'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useQuery } from '@tanstack/react-query'
import { type User, UserApi, type Project } from '../../common/services'
import axios from 'axios'
import { getFilterOptions } from './compare'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import { number, object, string } from 'yup'
import { toast } from 'react-toastify'

// const getVendors = (users?: User[]): User[] | undefined => {
//   return users?.filter(u => u.role === 'VENDOR')
// }

const getPanas = (users?: User[]): User[] | undefined => {
  return users?.filter(u => u.role === 'BOD')
}
const getVendors = (users?: User[]): User[] | undefined => {
  return users?.filter(u => u.role === 'VENDOR')
}

interface ProjectFormRequest extends Project {
  category_id: number
  approve_id: number
  file1?: File
  vendor_ids: number[] | string[]
}

const projectSchema = object().shape({
  name: string().required(),
  desciption: string().required(),
  started_at: string().required(),
  ended_at: string().required(),
  category_id: number().min(1, 'Please select category').required(),
  approve_id: number().min(1, 'Please select approval').required()
})

export default function ProjectForm (): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { data } = useQuery(['/users'], async () => {
    const { data } = await new UserApi().getUsers()
    return data as any
  })
  const categoriesQuery = useQuery(['/categories-tree'], async () => {
    const { data } = await axios.get('/categories')
    return data
  })
  const [filterObject] = useState<ProjectFormRequest>({
    id: 0,
    name: '',
    desciption: '',
    file: undefined,
    started_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    ended_at: moment().add(10, 'days').format('YYYY-MM-DD HH:mm:ss'),
    category_id: 0,
    approve_id: 0,
    vendor_ids: []
  })
  const onSubmit = async (values: ProjectFormRequest): Promise<void> => {
    setIsLoading(true)
    try {
      const formData = new FormData()
      // Update the formData object
      formData.append('id', values.id as any)
      formData.append('name', values.name as any)
      formData.append('desciption', values.desciption as any)
      formData.append('file', values.file1 as any)
      formData.append('started_at', values.started_at as any)
      formData.append('ended_at', values.ended_at as any)
      formData.append('category_id', values.category_id as any)
      formData.append('approve_id', values.approve_id as any)
      values.vendor_ids.forEach(id => {
        formData.append('vendor_ids[]', id as any)
      })

      await axios.post('/projects', formData)
      navigate('/')
    } catch (ex: any) {
      toast(ex.response.data.message, {
        type: 'error'
      })
    } finally {
      setIsLoading(false)
    }
  }
  return <Box>
    <Typography variant='h1' mb={4}>{t('Create project')}</Typography>
    <Formik
      initialValues={filterObject}
      enableReinitialize={true}
      validationSchema={projectSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
        <Form>
          <Grid container spacing={8}>
            <Grid item xs={8}>
              <Box mt={4} p={4} className="projectCard">

                <FormValiationMessages errors={errors} touched={touched} />
                <Typography variant='h4'>{t('Describe project')}</Typography>
                <Box mt={4} mb={7}>
                  <TextField value={values.name} onChange={handleChange('name')} helperText="eg: Digital marketing" label={t('Project name')} variant="outlined" fullWidth />
                </Box>
                <Box mb={7}>
                  <TextField value={values.desciption} onChange={handleChange('desciption')} multiline rows={14} label="Description" variant="outlined" fullWidth />
                </Box>
                <Box mb={7}>
                  <Button
                    variant="outlined"
                    component="label"
                  >
                    {t('Upload brief file')}
                    <input
                      type="file"
                      hidden
                      onChange={(event) => { setFieldValue('file1', (event.target.files != null) && event.target.files?.length > 0 ? event.target.files[0] : undefined) }}
                    />
                  </Button>
                  {values.file1 !== undefined && <Typography mt={3}>{t('Selected')} {values.file1.name}</Typography>}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mt={4} p={4} className="projectCard">
                <Typography variant='h4'>{t('Project advanced info')}</Typography>
                <Box mt={6} mb={5}>
                  <Typography>{t('Start time')}</Typography>
                  <DateTimePicker
                    value={moment(values.started_at)} onChange={(e) => { setFieldValue('started_at', moment(e).format('YYYY-MM-DD HH:mm:ss')) }}
                    slotProps={{
                      textField: {
                        fullWidth: true
                      }
                    }} />
                </Box>
                <Box mb={5}>
                  <Typography>{t('End time')}</Typography>
                  <DateTimePicker
                    value={moment(values.ended_at)} onChange={(e) => { setFieldValue('ended_at', moment(e).format('YYYY-MM-DD HH:mm:ss')) }} slotProps={{
                      textField: {
                        fullWidth: true
                      }
                    }} />
                </Box>
                <Box mb={5}>
                  <Typography>{t('Approve by')}</Typography>
                  <FormControl fullWidth>
                    <Select value={values.approve_id}
                      onChange={(event) => {
                        setFieldValue('approve_id', event.target.value)
                      }}>
                      <MenuItem value={0} disabled>
                        {t('Select approval')}
                      </MenuItem>
                      {getPanas(data)?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mb={5}>
                  <Typography>{t('Category')}</Typography>
                  <FormControl fullWidth>
                    <Select
                      value={values.category_id}
                      onChange={(event) => {
                        setFieldValue('category_id', event.target.value)
                      }}
                    >
                      <MenuItem value={0} disabled>
                        {t('Select category')}
                      </MenuItem>
                      {getFilterOptions(categoriesQuery.data)}
                    </Select>
                  </FormControl>
                </Box>
                <Box mb={5}>
                  <Typography>{t('Vendors')}</Typography>
                  <FormControl fullWidth>
                    <Select multiple value={values.vendor_ids}
                      onChange={(event) => {
                        console.log(event.target.value)
                        setFieldValue('vendor_ids', typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)
                      }}>
                      {getVendors(data)?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={4} display="flex" gap={4}>
                <Button disabled={isLoading} variant="contained" onClick={() => { handleSubmit() }}>Create project</Button>
                <Link to="/">
                  <Button>Back to list</Button>
                </Link>
              </Box>
            </Grid>
          </Grid>

        </Form>)}
    </Formik>
  </Box>
}

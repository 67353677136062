import React, { useState } from 'react'
import { Alert, Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { type LoginRequest, AuthApi } from '../../common/services'
import { object, string } from 'yup'
import { FormValiationMessages } from '../../common/components'
import localStorage from '../../common/localStorage'
import { toast } from 'react-toastify'

const loginSchema = object().shape({
  email: string().email().required(),
  password: string()
    .min(6)
    .max(50)
    .required()
})
const INIT_USER = {
  email: '',
  password: ''
}
export default function Login (): JSX.Element {
  const { t } = useTranslation()
  const native = useNavigate()
  const [registerObj, setRegisterObj] = useState({
    submitting: false,
    success: false
  })
  const [user, setUser] = useState<LoginRequest>(INIT_USER)
  const onSubmit = async (values: LoginRequest): Promise<void> => {
    try {
      setRegisterObj({
        submitting: true,
        success: false
      })
      const { data } = await new AuthApi().login(values)
      localStorage.setItem('LOGIN', data)
      const profileResponse = await new AuthApi().me()
      localStorage.setItem('USER', profileResponse.data)
      native('/')
      setUser(INIT_USER)
      setRegisterObj({
        submitting: false,
        success: true
      })
    } catch (ex: any) {
      toast(ex.response.data.message, {
        type: 'error'
      })
      setRegisterObj({
        submitting: false,
        success: false
      })
    }
  }
  return (
    <Box className="authWrapper">
      <Box className="authBox" p={4}>
        <img src={require('../../assets/images/Panasonic_logo.png')} alt='logo' height={26} style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto', marginBottom: 16 }} />
        <Typography align='center'>{t('Panasonic\'s project management and bidding system for partners.')}</Typography>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={loginSchema}
          onSubmit={(values: LoginRequest) => {
            void onSubmit(values)
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormValiationMessages errors={errors} touched={touched} />
              {
                registerObj.success && <Alert sx={{ mt: 4 }} severity="success">{t('Login successful! Welcome back.')}</Alert>
              }
              <Box mt={4}>
                <TextField label={t('Email')} variant="outlined" fullWidth
                  value={values.email}
                  onChange={handleChange('email')}
                />
              </Box>
              <Box mt={4}>
                <TextField type='password' label={t('Password')} variant="outlined" fullWidth
                  value={values.password}
                  onChange={handleChange('password')}
                />
              </Box>
              <Box mt={4}>
                <Button variant="contained" type='submit' disabled={registerObj.submitting} fullWidth onClick={() => { handleSubmit() }}>{t('Login')}</Button>
              </Box>
              <Box mt={4} sx={{ textAlign: 'center' }}>
                <Typography>{t('Do not have an account?')}</Typography>
                <Typography component={Link} to="/register"> {t('Register')}</Typography>
              </Box>
            </Form>)}
        </Formik>
      </Box>
    </Box>
  )
}

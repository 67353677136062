/* eslint-disable react/no-unescaped-entities */
import * as React from 'react'
import { Avatar, Box, Button, CircularProgress, Grid, List, ListItem, ListItemAvatar, ListItemText, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
// import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import Countdown, { type CountdownRenderProps } from 'react-countdown'
import { ProjectApi, type User } from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import { showDate } from '../../common/time'
import moment from 'moment'
import { nl2br, stringAvatar } from '../../common/helper'
import localStorage from '../../common/localStorage'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AttachFileIcon from '@mui/icons-material/AttachFile'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export function TabPanel (props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  )
}

export function a11yProps (index: number): any {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function ProjectDetail (): JSX.Element {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  const user: User = localStorage.getItem('USER')
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, isLoading } = useQuery(['/my-projects', id], async () => {
    const { data } = await new ProjectApi().showProject(id ?? '0')
    return data
  })
  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps): JSX.Element => {
    return <Box className='countdown'>
      <span className="countdownItem"><span className="countdownValue">{days}</span> {t('Days')}</span>
      <span className="countdownItem"><span className="countdownValue">{hours}</span> {t('Hours')}</span>
      <span className="countdownItem"><span className="countdownValue">{minutes}</span> {t('Minutes')}</span>
      <span className="countdownItem"><span className="countdownValue">{seconds}</span> {t('Seconds')}</span>
    </Box>
  }
  if (isLoading) {
    return <Box display="flex" justifyContent="center" py={150}><CircularProgress /></Box>
  }
  return (
    <Box>
      <Typography variant='h1' mb={5}>#00{data?.id} {data?.name}</Typography>
      <Grid container spacing={8} >
        <Grid item xs={8}>
          <Box className="projectCard">
            <Box display="flex" mb={3}>
              <Avatar alt={(data as any).user.name} src={(data as any).user.avatar} {...stringAvatar((data as any)?.user.name)} />
              <Box ml={3}>
                <Typography variant='h5'>{(data as any).user.name}</Typography>
                <Typography variant='body1'>{(data as any).user.title}</Typography>
              </Box>
            </Box>
            <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider', mb: 4 }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Detail" {...a11yProps(0)} />
                <Tab label="Documents" {...a11yProps(1)} />
                {
                  data?.timeline !== undefined && data.timeline.length > 0 && <Tab label="Timeline" {...a11yProps(1)} />
                }
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Typography variant='h3' mb={4}>{t('Description')}</Typography>
              <div dangerouslySetInnerHTML={{ __html: nl2br(data?.desciption ?? '') }} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {data?.file !== null && data?.file !== '' &&
                <ListItem onClick={() => { window.open(data?.file, '_blank')?.focus() }}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'rgb(255, 87, 34)' }}>
                      <AttachFileIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={t('Project brief')}
                    secondary={t('Click to view')}
                  />
                </ListItem>
              }
              {(data?.file === null || data?.file === '') &&
                <Box py={4}>
                  <Typography >{t('No documents')}</Typography>
                </Box>
              }
            </TabPanel>
            {
              data?.timeline !== undefined && data.timeline.length > 0 &&
              <TabPanel value={value} index={2}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  {data?.timeline.map((timeline) => {
                    return <ListItem key={timeline.id}>
                      <ListItemAvatar>
                        <Avatar>
                          <AccessTimeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={timeline.content} secondary={showDate(timeline.created_at)} />
                    </ListItem>
                  })}
                </List>
              </TabPanel>
            }
          </Box>
        </Grid >

        <Grid item xs={4}>
          <Box className="projectCard">
            <Typography variant='h4' mb={4}>{t('About')}</Typography>
            <Box mb={4}>
              <Typography variant='caption' display="block" mb={1} >{t('Started at')}</Typography>
              <Typography fontWeight="500">{showDate(data?.started_at)}</Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='caption' display="block" mb={1} >{t('Ended at')}</Typography>
              <Typography fontWeight="500" mb={2}>{showDate(data?.ended_at)}</Typography>
              <Countdown
                date={moment(data?.ended_at).toDate()}
                renderer={renderer}
              />
            </Box>

            {(user.role === 'PIC' || user.role === 'BOD') && <>
              <Box mb={4}>
                <Typography variant='caption' display="block" mb={1} >{t('Approval')}</Typography>
                <Box display="flex">
                  <Avatar alt={(data as any)?.approval.name} src={(data as any)?.approval.avatar} {...stringAvatar((data as any)?.approval.name)} />
                  <Box ml={3}>
                    <Typography variant='body1' fontWeight={500}>{(data as any)?.approval.name}</Typography>
                    <Typography variant='caption'>{(data as any)?.approval.title}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box mb={4}>
                <Typography variant='caption' display="block" mb={1} >{t('Vendors')}</Typography>
                {(data as any)?.vendors.length === 0 && <Typography fontWeight="500">{t('No data')}</Typography>}
                {(data as any)?.vendors?.map((user: User): JSX.Element => {
                  return <Box display="flex" key={user.id} mb={3}>
                    <Avatar alt={user.name} {...stringAvatar(user.name)} />
                    <Box ml={3}>
                      <Typography variant='body1' fontWeight={500}>{user.name}</Typography>
                      <Typography variant='caption'>{user.title}</Typography>
                    </Box>
                  </Box>
                })}
              </Box>
              {moment().unix() > moment(data?.ended_at).unix() &&
                <Grid item xs={12}>
                  <Link to={`/projects/${id ?? ''}/compare`}>
                    <Button variant="contained" fullWidth>{t('View submission')}</Button>
                  </Link>
                  {/* {user.role === 'BOD' && <Link to={`/projects/${id ?? ''}/selection`}>
                    <Button variant="text" fullWidth sx={{ mt: 5 }}>{t('View selection')}</Button>
                  </Link>} */}
                </Grid>
              }
            </>
            }
            {user.role === 'VENDOR' &&
              <>
                {moment().unix() < moment(data?.ended_at).unix() &&
                  <>
                    <Typography variant='h4' mb={2}>{t('Send us your proposal and budget plan for a personalized quote!')}</Typography>
                    <Typography>
                      Ready to get started? You can submit multiple quotations within the deadline! Click the button below to send us your proposal and budget plan. Our team is eager to review each submission, allowing you to refine your requirements and receive a tailored quote that precisely matches your needs. Take full advantage of this opportunity to bring your vision to life by clicking 'Send a Quote' now!
                    </Typography>
                    <Grid container spacing={3} mt={1}>
                      {(data as any)?.is_submitted !== true &&
                        <Grid item xs={6}>
                          <Link to={`/projects/${id ?? ''}/submit`}>
                            <Button variant="contained" fullWidth>{t('Send a Quote')}</Button>
                          </Link>
                        </Grid>
                      }
                      {(data as any)?.is_submitted === true &&
                        <Grid item xs={6}>
                          <Link to={`/projects/${id ?? ''}/preview`}>
                            <Button variant="text" fullWidth>{t('View submission')}</Button>
                          </Link>
                        </Grid>
                      }
                    </Grid>
                  </>
                }
                {moment().unix() >= moment(data?.ended_at).unix() &&
                  <>
                    <Typography variant='h4' mb={2}>{t('Thank you for submitting your quote!')}</Typography>
                    <Typography>
                      We appreciate your interest in our services. Our team will carefully review your proposal and budget plan. We will get back to you shortly with a personalized quote that aligns with your requirements. If we have any further questions or need additional information, we will reach out to you. We look forward to the opportunity to work with you!
                    </Typography>
                  </>
                }
              </>
            }
            {
              moment().unix() >= moment(data?.ended_at).unix() && user.role !== 'VENDOR' && data?.status === 'Finished' &&
              <Box mt={3}>
                <Link to={data?.report_url ?? '#'}>
                  <Button variant="outlined" fullWidth>{t('View approved document')}</Button>
                </Link>
              </Box>
            }
          </Box>
        </Grid>
      </Grid >
    </Box >
  )
}

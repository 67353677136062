import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import { ModalContent } from '../../../common/components'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import React from 'react'

interface WinningModalProps {
  open: boolean
  type: any
  onClose: (values?: {
    content: string
  }) => void
}

export default function ApproveModal ({ open, type, onClose }: WinningModalProps): JSX.Element {
  const { t } = useTranslation()
  const onSubmit = (values: any): void => {
    onClose(values)
  }
  return <Modal
    open={open}
    onClose={() => { onClose() }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box>
      <ModalContent onClose={() => { onClose() }} title={'Approve project'}>
        <Formik
          initialValues={{ content: '' }}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
            <Form>
              <Typography>This message will be send to PIC, and system will automatic send an email to Vendor if they win to let they know.</Typography>
              <Box mt={4}>
                <TextField value={values.content} onChange={handleChange('content')} multiline rows={12} label="Note" variant="outlined" fullWidth />
              </Box>
              <Box display="flex" justifyContent="end" gap={4} mt={5}>
                <Button onClick={() => { onClose() }}>{t('Cancel')}</Button>
                <Button variant="contained" onClick={() => { handleSubmit() }}>{t('Save')}</Button>
              </Box>
            </Form>)}
        </Formik>
      </ModalContent>
    </Box>
  </Modal>
}

import React from 'react'
import { Box, Typography } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid'
import { StyledDataGrid } from '../../../common/components'
import { useTranslation } from 'react-i18next'
import { formatter } from '../../../common/helper'
import { showDate } from '../../../common/time'
export default function TableView ({ filterResult }: { filterResult: any[] }): JSX.Element {
  const { t } = useTranslation()
  const getColumns = (rows: any): GridColDef[] => {
    const columnsSystem: GridColDef[] = [
      {
        field: 'name',
        headerName: t('Vendor Name') ?? '',
        minWidth: 200,
        flex: 1
      }
    ]
    rows[0].columns.forEach((column: any, index: number) => {
      columnsSystem.push({
        field: `col${column.id as string}`,
        headerName: `${t(column.category_column.title as string) ?? ''} (${t(column.category_column.unit as string) ?? ''}) ` ?? '',
        minWidth: 200,
        flex: 1,
        valueGetter: ({ row }) => {
          return formatter.format(row.columns[index].value)
        }
      })
    })
    columnsSystem.push({
      field: 'created_at',
      headerName: t('Created at') ?? '',
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => {
        return (row.created_at != null) ? showDate(row.created_at) : '-'
      }
    })
    return columnsSystem
  }
  return (
    <Box>
      {filterResult.map((group: any): JSX.Element => {
        return <Box mt={4} className="projectCard" key={group.key}>
          <Box className="tableTools">
            <Typography variant='h4'>{group.key}</Typography>
          </Box>
          <Box>
            <StyledDataGrid autoHeight rowHeight={50} getRowId={(row) => row.user_id} rows={group.rows} columns={getColumns(group.rows)} rowSelection={false} disableColumnMenu={true} />
          </Box>
        </Box>
      })}
    </Box>
  )
}

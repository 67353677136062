/* tslint:disable */
/* eslint-disable */
/**
 * My First API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Project } from '../models';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignedProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assigned/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/my/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Display the specified resource.
         * @summary Display the specified resource.
         * @param {string} id The event ID specific to this event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showProject', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignedProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignedProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Display the specified resource.
         * @summary Display the specified resource.
         * @param {string} id The event ID specific to this event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignedProjects(options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.assignedProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * Display a listing of the resource.
         * @summary Display a listing of the resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myProjects(options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.myProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * Display the specified resource.
         * @summary Display the specified resource.
         * @param {string} id The event ID specific to this event
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProject(id: string, options?: any): AxiosPromise<Project> {
            return localVarFp.showProject(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public assignedProjects(options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).assignedProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Display a listing of the resource.
     * @summary Display a listing of the resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjects(options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Display a listing of the resource.
     * @summary Display a listing of the resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public myProjects(options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).myProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Display the specified resource.
     * @summary Display the specified resource.
     * @param {string} id The event ID specific to this event
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public showProject(id: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).showProject(id, options).then((request) => request(this.axios, this.basePath));
    }
}

import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

interface ModalHeaderProps {
  title: string
  onClose: () => void
  children: React.ReactNode
}

export default function ModalContent ({ title, onClose, ...otherProps }: ModalHeaderProps): JSX.Element {
  return <Box sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 1024,
    bgcolor: 'background.paper',
    py: 3,
    px: 4,
    borderRadius: 4,
    boxDhadow: '0px 9px 46px 8px rgba(0,0,0, 0.12), 0px 24px 38px 3px rgba(0,0,0, 0.14), 0px 11px 15px -7px rgba(0,0,0, 0.2)'
  }}>
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
      <Typography variant='h3'>{title}</Typography>
      <IconButton onClick={onClose}>
        <CloseOutlinedIcon />
      </IconButton>
    </Box>
    <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto', mx: -4, px: 4 }} {...otherProps} />
  </Box>
}

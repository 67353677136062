import React from 'react'
import { Avatar, Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectApi, type User } from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { showDate } from '../../common/time'
import Countdown, { type CountdownRenderProps } from 'react-countdown'
import localStorage from '../../common/localStorage'
import { formatter, stringAvatar } from '../../common/helper'
import { Colors } from '../../common/PanaTheme'

export default function ProjectPreview (): JSX.Element {
  const { t } = useTranslation()
  const { id } = useParams()
  const user: User = localStorage.getItem('USER')
  const { data, isLoading } = useQuery(['/my-projects', id], async () => {
    const { data } = await new ProjectApi().showProject(id ?? '0')
    return data
  })
  const previewQuery = useQuery(['/previewSubmit', id], async () => {
    const { data } = await axios.get(`/previewSubmit/${id ?? 0}`)
    return data
  })

  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps): JSX.Element => {
    return <Box className='countdown'>
      <span className="countdownItem"><span className="countdownValue">{days}</span> {t('Days')}</span>
      <span className="countdownItem"><span className="countdownValue">{hours}</span> {t('Hours')}</span>
      <span className="countdownItem"><span className="countdownValue">{minutes}</span> {t('Minutes')}</span>
      <span className="countdownItem"><span className="countdownValue">{seconds}</span> {t('Seconds')}</span>
    </Box>
  }

  if (isLoading) {
    return <Box display="flex" justifyContent="center" py={150}><CircularProgress /></Box>
  }
  if ((data as any)?.is_submitted === false && moment().unix() >= moment(data?.ended_at).unix()) {
    return (<Box className="main" p={4}>
      <Typography variant='h4' mb={2}>{t('You have missed, Deadline has passed!')}</Typography>
      <Typography>
        Oops! It looks like the deadline for submitting proposals has already passed. We appreciate your interest, but we are no longer accepting new submissions at this time. If you have any questions or need further assistance, please feel free to contact our team. Thank you for your understanding!
      </Typography>
    </Box>)
  }
  if (moment().unix() >= moment(data?.ended_at).unix()) {
    return (<Box className="projectCard">
      <Typography variant='h4' mb={2}>{t('You have missed, Deadline has passed!')}</Typography>
      <Typography>
        Oops! It looks like the deadline for submitting proposals has already passed. We appreciate your interest, but we are no longer accepting new submissions at this time. If you have any questions or need further assistance, please feel free to contact our team. Thank you for your understanding!
      </Typography>
    </Box>)
  }

  return (
    <Box>
      <Typography variant='h1' mb={4}>{t('Submission detail')}</Typography>
      <Box className="projectCard">
        <Box mb={4}>
          <Typography variant='caption' display="block" mb={1} >{t('Project')}</Typography>
          <Typography fontWeight="500">#00{data?.id} {data?.name}</Typography>
        </Box>
        <Box mb={4}>
          <Typography variant='caption' display="block" mb={1} >{t('Ended at')}</Typography>
          <Typography fontWeight="500" mb={2}>{showDate(data?.ended_at)}</Typography>
          <Countdown
            date={moment(data?.ended_at).toDate()}
            renderer={renderer}
          />
        </Box>
        <Box mb={4}>
          <Typography variant='caption' display="block" mb={1} >{t('Vendor')}</Typography>
          <Box display="flex">
            <Avatar alt={user?.name} {...stringAvatar(user?.name)} />
            <Box ml={3}>
              <Typography variant='body1'>{user?.name}</Typography>
              <Typography variant='caption'>{user?.title}</Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant='h4' mb={4}>{t('Preview quotation')}</Typography>
        {previewQuery.data?.map((cate: any, index: number): JSX.Element => {
          return <Box mb={3} key={index}>
            <Typography variant='h5' color={Colors.primary} mb={4}>{cate.name}</Typography>

            <TableContainer component={Paper} sx={{ mt: 4, mb: 8 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {cate.data.map((row: any, index2: number) => (
                    <TableRow
                      key={index2}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>{row.title}</TableCell>
                      {
                        row.columns.map((col: any) => {
                          if (index2 === 0) {
                            return <TableCell key={col.id}>{col.title} ({col.unit})</TableCell>
                          } else {
                            return <TableCell key={col.id}>{formatter.format(col.value)}</TableCell>
                          }
                        })
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        })}
        <Box display="flex" justifyContent="flex-end">
          <Link to={`/projects/${id ?? ''}/submit`}>
            <Button
              variant="text"
              style={{ marginRight: 20 }}
            >
              {t('Submit again')}
            </Button>
          </Link>
          <Link to={`/projects/${id ?? ''}`}>
            <Button
              variant="contained"
            >
              {t('Go to project')}
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

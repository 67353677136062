import React, { useState } from 'react'
import { Alert, Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { UserApi, type User } from '../../common/services'
import { object, string } from 'yup'
import { FormValiationMessages } from '../../common/components'
import { toast } from 'react-toastify'

const signupSchema = object().shape({
  name: string()
    .min(2)
    .max(50)
    .required(),
  phone: string()
    .min(2)
    .max(50)
    .required(),
  email: string().email().required(),
  title: string().required(),
  password: string()
    .min(6)
    .max(50)
    .required()
})
const INIT_USER = {
  id: 0,
  email: '',
  title: '',
  name: '',
  phone: '',
  password: ''
}
export default function Login (): JSX.Element {
  const { t } = useTranslation()
  const [registerObj, setRegisterObj] = useState({
    submitting: false,
    success: false
  })
  const [user, setUser] = useState<User>(INIT_USER)
  const onSubmit = async (values: User): Promise<void> => {
    try {
      setRegisterObj({
        submitting: true,
        success: false
      })
      void await new UserApi().createUser(values)
      setUser(INIT_USER)
      setRegisterObj({
        submitting: false,
        success: true
      })
    } catch (ex: any) {
      toast(ex.response.data.message, {
        type: 'error'
      })
      setRegisterObj({
        submitting: false,
        success: false
      })
    }
  }
  return (
    <Box className="authWrapper">
      <Box className="authBox" p={4}>
        <img src={require('../../assets/images/Panasonic_logo.png')} alt='logo' height={26} style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto', marginBottom: 16 }} />
        <Typography align='center'>{t('Panasonic\'s project management and bidding system for partners.')}</Typography>
        {
          registerObj.success && <Alert sx={{ mt: 4 }} severity="success">{t('Congratulations! You\'ve successfully registered. Your account is pending approval. Once approved, you\'ll be able to log in and access all the features. Thank you for your patience!')}</Alert>
        }

        {!registerObj.success &&
          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={signupSchema}
            onSubmit={(values: User) => {
              void onSubmit(values)
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form>
                <FormValiationMessages errors={errors} touched={touched} />
                <Box mt={4}>
                  <TextField label={t('Your name')} variant="outlined" fullWidth
                    value={values.name}
                    onChange={handleChange('name')}
                  />
                </Box>
                <Box mt={4}>
                  <TextField label={t('Job title')} variant="outlined" fullWidth
                    value={values.title}
                    onChange={handleChange('title')}
                  />
                </Box>
                <Box mt={4}>
                  <TextField label={t('Email')} variant="outlined" fullWidth
                    value={values.email}
                    onChange={handleChange('email')}
                  />
                </Box>
                <Box mt={4}>
                  <TextField label={t('Phone number')} variant="outlined" fullWidth
                    value={values.phone}
                    onChange={handleChange('phone')}
                  />
                </Box>
                <Box mt={4}>
                  <TextField type='password' label={t('Password')} variant="outlined" fullWidth
                    value={values.password}
                    onChange={handleChange('password')}
                  />
                </Box>
                <Box mt={4}>
                  <Button variant="contained" disabled={registerObj.submitting} fullWidth onClick={() => { handleSubmit() }}>{t('Register')}</Button>
                </Box>
              </Form>)}
          </Formik>
        }
        <Box mt={4} sx={{ textAlign: 'center' }}>
          <Typography>{t('Do have an account?')}</Typography>
          <Typography component={Link} to="/login"> {t('Back to login')}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

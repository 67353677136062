import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, CircularProgress, Grid, ListItemText, MenuItem, Select, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChartView, TableView } from './components'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { ProjectApi } from '../../common/services'
import { arrayToTree } from '../../common/helper'
import { Form, Formik } from 'formik'
import axios from 'axios'
import { groupBy, uniq } from 'lodash'
import moment from 'moment'
type ViewByEnum = 'table' | 'chart'

// TODO: Optimize this stupid idea
export const getFilterOptions = (filterByCategories?: any[]): JSX.Element[] => {
  const els: JSX.Element[] = []
  if (filterByCategories !== undefined && filterByCategories?.length > 0) {
    filterByCategories?.forEach(cate => {
      if (cate.canInput === 0) { els.push(<MenuItem key={cate.id} value={cate.id} disabled={cate.is_active === 0}>{cate.title}</MenuItem>) }
      cate.children?.forEach((cate2: any) => {
        if (cate2.canInput === 0) { els.push(<MenuItem key={cate2.id} sx={{ pl: 8 }} value={cate2.id} disabled={cate2.is_active === 0}>{cate2.title}</MenuItem>) }
        cate2.children?.forEach((cate3: any) => {
          if (cate3.canInput === 0) { els.push(<MenuItem key={cate3.id} sx={{ pl: 12 }} value={cate3.id} disabled={cate3.is_active === 0}>{cate3.title}</MenuItem>) }
          cate3.children?.forEach((cate4: any) => {
            if (cate4.canInput === 0) { els.push(<MenuItem key={cate4.id} sx={{ pl: 16 }} value={cate4.id} disabled={cate4.is_active === 0}>{cate4.title}</MenuItem>) }
            cate4.children?.forEach((cate5: any) => {
              if (cate5.canInput === 0) { els.push(<MenuItem key={cate5.id} sx={{ pl: 16 }} value={cate5.id} disabled={cate5.is_active === 0}>{cate5.title}</MenuItem>) }
            })
          })
        })
      })
    })
  }
  return els
}
export default function ProjectCompare (): JSX.Element {
  const { t } = useTranslation()
  const [filterObject] = useState<any>({
    viewBy: 'table',
    category_id: 0,
    user_ids: [],
    categories: []
  })
  const [listCategories, setListCategories] = useState<any[]>([])
  const [filterByCategories, setFilterByCategories] = useState<any[]>([])
  const [filterResult, setFilterResult] = useState<any>([])
  const [allCatColumn, setAllCatColumn] = useState<string[]>([])
  const [isLoadingFilter, setIsLoadingFilter] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const { id } = useParams()
  const { data, isLoading } = useQuery(['/projects', id], async () => {
    const { data } = await new ProjectApi().showProject(id ?? '0')
    return data
  })
  const onFilter = async (values: any): Promise<void> => {
    try {
      setIsLoadingFilter(true)
      const dataProject = await axios.post('/data/projects', {
        project_id: id,
        ids: values.categories
      })
      let dataProjectData = dataProject.data
      const array = dataProjectData.map((item: any) => {
        return item.category_column.title
      })
      setAllCatColumn(uniq(array))
      dataProjectData = dataProjectData.filter((u: any) => {
        return values.user_ids.length === 0 ? true : values.user_ids.includes(u.user_id)
      })
      const groupByTitleData: any = groupBy(dataProjectData, (row) => {
        return row.category_column.category.title
      })
      const dataGroup = Object.keys(groupByTitleData).map((key): any => {
        const groupByUserData = groupBy(groupByTitleData[key], (data) => {
          return data.user_id
        })
        const rows = Object.keys(groupByUserData).map((id) => {
          const dataRow = dataProjectData.find((d: any) => Number(d.user_id) === Number(id))
          const row: any = {
            name: dataRow.user.name,
            user_id: dataRow.user_id,
            columns: []
          }
          groupByUserData[id].forEach((r: any) => {
            row.columns.push(r)
            if (!Object.hasOwn(row, 'created_at')) {
              row.created_at = r.created_at
            }
          })
          return row
        })
        return {
          key,
          rows
        }
      })
      setFilterResult(dataGroup)
    } catch (ex: any) {
      alert(ex.message)
    } finally {
      setIsLoadingFilter(false)
    }
  }
  useEffect(() => {
    if (data != null) {
      const cateData = (data as any)?.categories ?? []
      setFilterByCategories(arrayToTree(cateData, -1))
    }
  }, [data])

  if (isLoading) {
    return <Box display="flex" justifyContent="center" py={150}><CircularProgress /></Box>
  }
  if (moment().unix() <= moment(data?.ended_at).unix()) {
    return (<Box className="projectCard">
      <Typography variant='h4' mb={2}>{t('Deadline has not passed!')}</Typography>
      <Typography>
        Oops! It looks like the deadline for submitting proposals has not passed. We appreciate your interest, but we are no longer accepting to view project report at this time. If you have any questions or need further assistance, please feel free to contact our team. Thank you for your understanding!
      </Typography>
    </Box>)
  }
  return (
    <Box>
      <Typography variant='h1' mb={5}>#00{data?.id} {data?.name} - Compare</Typography>
      <Formik
        initialValues={filterObject}
        enableReinitialize={true}
        onSubmit={(values: any) => {
          void onFilter(values)
        }}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => (
          <Form>
            <Box className="projectCard">
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Box mb={1}>{t('View by')}</Box>
                  <Select size='small' fullWidth
                    value={values.viewBy}
                    onChange={(event) => { setFieldValue('viewBy', event.target.value as ViewByEnum) }}
                  >
                    <MenuItem value={'table'}>{t('Table')}</MenuItem>
                    <MenuItem value={'chart'}>{t('Chart')}</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Box mb={1}>{t('Vendor')}</Box>
                  <Select size='small' fullWidth
                    multiple
                    value={values.user_ids}
                    onChange={(event) => {
                      setFieldValue('user_ids', event.target.value)
                      setSelectAll(false)
                    }}
                    renderValue={(selected) => {
                      if (selected.length > 0) {
                        const cats = (data as any)?.submitted_vendors?.filter((c: any): boolean => selected.includes(c.id))
                        return cats.map((c: any) => c.name).join(', ')
                      }
                      return t('Select') ?? ''
                    }}
                  >
                    {(data as any)?.submitted_vendors?.map((cat: any) =>
                      <MenuItem key={cat.id} value={cat.id}>
                        <Checkbox checked={values.user_ids.indexOf(cat.id) > -1} />
                        <ListItemText primary={cat.name} />
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Box mb={1}>{t('Filter by')}</Box>
                  <Select size='small' fullWidth
                    value={values.category_id}
                    onChange={(event) => {
                      setFieldValue('category_id', event.target.value)
                      setSelectAll(false)
                      const cats = (data as any)?.categories?.filter((c: any): boolean => c.parent_id === Number(event.target.value))
                      if (cats.length > 0 && cats[0].canInput === 1) {
                        setListCategories(cats)
                        setFieldValue('categories', [])
                      } else {
                        setListCategories([])
                        setFieldValue('categories', [])
                      }
                    }}
                  >
                    {getFilterOptions(filterByCategories)}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Box mb={1}>{t('Advanced filter')}</Box>
                  <Select size='small' fullWidth
                    multiple
                    value={values.categories}
                    onChange={(event) => {
                      const isCheckAll = event.target.value !== undefined && (event.target.value as any[]).findIndex((v: number) => v === -1) > -1
                      if (isCheckAll && selectAll) {
                        setSelectAll(false)
                        setFieldValue('categories', [])
                      } else if (isCheckAll && !selectAll) {
                        setSelectAll(true)
                        setFieldValue('categories', listCategories.map((c: any) => c.id))
                      } else {
                        setFieldValue('categories', event.target.value)
                      }
                    }}
                    renderValue={(selected) => {
                      if (selected.length > 0) {
                        const cats = (data as any)?.categories?.filter((c: any): boolean => selected.includes(c.id))
                        return cats.map((c: any) => c.title).join(', ')
                      }
                      return t('Select') ?? ''
                    }}
                  >
                    <MenuItem value={-1}>
                      <Checkbox checked={selectAll} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                    {listCategories.map(cat =>
                      <MenuItem key={cat.id} value={cat.id}>
                        <Checkbox checked={values.categories.indexOf(cat.id) > -1} />
                        <ListItemText primary={`${cat.group_name as string} - ${cat.title as string}`} />
                      </MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item xs={2}>
                  <Box mb={1}>&nbsp;</Box>
                  <Button variant="outlined" onClick={() => { handleSubmit() }} fullWidth>{t('Filter')}</Button>
                </Grid>
                {/* <Grid item xs={2} ml="auto">
                  <Box mb={1}>{t('Next step')}</Box>
                  <Link to={`/projects/${id ?? ''}/selection`}>
                    <Button variant="contained" fullWidth>{t('Selection')}</Button>
                  </Link>
                </Grid> */}
              </Grid>
            </Box>
            {isLoadingFilter && <Box display="flex" justifyContent="center" py={10}><CircularProgress /></Box>}
            {!isLoadingFilter && filterResult.length === 0 && <Box my={10} textAlign="center">
              <Typography >{t('No data. Please select or change the filter to view data')}</Typography>
            </Box>
            }
            {!isLoadingFilter && <Box>
              {values.viewBy === 'table' && <TableView filterResult={filterResult} />}
              {values.viewBy === 'chart' && <ChartView filterResult={filterResult} allCatColumn={allCatColumn} />}
            </Box>
            }
          </Form>)}
      </Formik>
    </Box>
  )
}

import React, { useState } from 'react'
import { Avatar, Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectApi, type User } from '../../common/services'
import { useQuery } from '@tanstack/react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { showDate } from '../../common/time'
import Countdown, { type CountdownRenderProps } from 'react-countdown'
import localStorage from '../../common/localStorage'
import { stringAvatar } from '../../common/helper'
import { toast } from 'react-toastify'

export default function ProjectSubmit (): JSX.Element {
  const { t } = useTranslation()
  const { id } = useParams()
  const native = useNavigate()
  const user: User = localStorage.getItem('USER')
  const [file, setFile] = useState<File>()
  const [description, setDescription] = useState<string>('')
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const { data, isLoading } = useQuery(['/my-projects', id], async () => {
    const { data } = await new ProjectApi().showProject(id ?? '0')
    return data
  })
  const handleSubmit = (): void => {
    if (file !== undefined && description !== '') {
      const formData = new FormData()
      // Update the formData object
      formData.append(
        'file',
        file as any
      )
      formData.append(
        'description',
        description
      )
      setIsSubmit(true)
      void axios.post(`/applyTemplate/${id ?? ''}`, formData).then(() => {
        native(`/projects/${id ?? ''}/preview`)
      }).catch((ex: any) => {
        alert(ex.message)
      }).finally(() => {
        setIsSubmit(false)
      })
    } else {
      toast(t('Please fill description and quotation file before the submit'), {
        type: 'error'
      })
    }
  }

  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps): JSX.Element => {
    return <Box className='countdown'>
      <span className="countdownItem"><span className="countdownValue">{days}</span> {t('Days')}</span>
      <span className="countdownItem"><span className="countdownValue">{hours}</span> {t('Hours')}</span>
      <span className="countdownItem"><span className="countdownValue">{minutes}</span> {t('Minutes')}</span>
      <span className="countdownItem"><span className="countdownValue">{seconds}</span> {t('Seconds')}</span>
    </Box>
  }

  if (isLoading) {
    return <Box display="flex" justifyContent="center" py={150}><CircularProgress /></Box>
  }
  if (moment().unix() >= moment(data?.ended_at).unix()) {
    return (<Box className="projectCard">
      <Typography variant='h4' mb={2}>{t('You have missed, Deadline has passed!')}</Typography>
      <Typography>
        Oops! It looks like the deadline for submitting proposals has already passed. We appreciate your interest, but we are no longer accepting new submissions at this time. If you have any questions or need further assistance, please feel free to contact our team. Thank you for your understanding!
      </Typography>
    </Box>)
  }

  return (
    <Box>
      <Typography variant='h1' mb={4}>{t('Submission detail')}</Typography>
      <Box className="projectCard">
        <Box mb={4}>
          <Typography variant='caption' display="block" mb={1} >{t('Project')}</Typography>
          <Typography fontWeight="500">#00{data?.id} {data?.name}</Typography>
        </Box>
        <Box mb={4}>
          <Typography variant='caption' display="block" mb={1} >{t('Ended at')}</Typography>
          <Typography fontWeight="500" mb={2}>{showDate(data?.ended_at)}</Typography>
          <Countdown
            date={moment(data?.ended_at).toDate()}
            renderer={renderer}
          />
        </Box>
        <Box mb={4}>
          <Typography variant='caption' display="block" mb={1} >{t('Vendor')}</Typography>
          <Box display="flex">
            <Avatar alt={user?.name} {...stringAvatar(user?.name)} />
            <Box ml={3}>
              <Typography variant='body1'>{user?.name}</Typography>
              <Typography variant='caption'>{user?.title}</Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant='h4' mb={4}>{t('Submission description')}</Typography>
        <TextField value={description} onChange={(e) => { setDescription(e.target.value) }} multiline rows={15} label="Description" variant="outlined" fullWidth />
        <Typography variant='h4' mb={2} mt={4}>{t('Quotation file')}</Typography>
        <Typography display="block" mb={4}>
          Please download our template by clicking the button below. Fill in your costs and details within the provided sheet. Once you&apos;ve completed the template, return to this section to upload the file. Our team will review the information you&apos;ve provided to generate an accurate quote tailored to your needs. We appreciate your cooperation and look forward to receiving your quotation file.
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="outlined"
            component="label"
          >
            {t('Upload Quotation file')}
            <input
              type="file"
              hidden
              onChange={(event) => { setFile((event.target.files != null) && event.target.files?.length > 0 ? event.target.files[0] : undefined) }}
            />
          </Button>
          <span>Only accept template file. <Link to={(data as any)?.template_url}>Download template here</Link></span>
        </Box>
        {file !== undefined && <Typography mt={3}>{t('Selected')} {file.name}</Typography>}

        {/* <Typography variant='h4' mb={2} mt={10}>{t('Proposal file')}</Typography>
        <Typography display="block" mb={4}>
          Please upload your proposal file with a maximum size of 20MB. Double-check content for accuracy. Click Upload, then select the file.
        </Typography>
        <Button
          variant="outlined"
          component="label"
        >
          {t('Upload Proposal file')}
          <input
            type="file"
            hidden
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
            onChange={(event) => { setBrief((event.target.files != null) && event.target.files?.length > 0 ? event.target.files[0] : undefined) }}
          />
        </Button>
        {brief !== undefined && <Typography mt={3}>{t('Selected')} {brief.name}</Typography>} */}
        <Box mt={6} display="flex" justifyContent="flex-end">
          <Link to={`/projects/${id ?? ''}`} style={{ marginRight: 10 }}>
            <Button variant="text" fullWidth>{t('Back to project')}</Button>
          </Link>
          <Button
            variant="contained"
            component="label"
            onClick={handleSubmit}
            disabled={isSubmit}
          >
            {t('Submit quotation')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

import * as React from 'react'
import { Avatar, Box, Button, CircularProgress, Container, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ProjectApi, type Project, type User } from '../../common/services'
import localStorage from '../../common/localStorage'
import { stringAvatar } from '../../common/helper'
import { Colors } from '../../common/PanaTheme'
import { showDate } from '../../common/time'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp'
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded'
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt'
import moment from 'moment'

export default function Projects (): JSX.Element {
  const { t } = useTranslation()
  const user: User = localStorage.getItem('USER')
  const [paginationModel] = React.useState({
    page: 0,
    pageSize: 10
  })
  const { data, isLoading, isFetching, refetch } = useQuery(['/my/projects'], async () => {
    const { data } = await new ProjectApi().myProjects()
    return data as any
  }, { enabled: false })
  React.useEffect(() => {
    void refetch()
  }, [paginationModel])
  return (
    <Container maxWidth={'md'}>
      <Typography variant='h1'>{t('Projects')}</Typography>
      <Box className="welcomeBox">
        <div>
          <Typography variant='h2' mb={3}>
            Welcome 👋, <br />
            {user.name}
          </Typography>
          <Typography>
            If you are going to use a passage of Lorem Ipsum,<br />you need to be sure there isn&apos;t anything.
          </Typography>
        </div>
        <img src={require('../../assets/images/character.png')} alt="character" />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box pt={4} mb={7} display="flex" justifyContent="space-between">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder={t('Search') ?? ''}
            size='small'
          />
          {user.role === 'PIC' && <Link to={'/new/project'}>
            <Button variant="contained">{t('New project')}</Button></Link>}
        </Box>
        <Box>
          {(isLoading || isFetching) && <Box display="flex" justifyContent="center" py={150}><CircularProgress /></Box>}
          {!(isLoading && isFetching) && data?.data.map((project: Project) => {
            return <Box key={project.id} className="projectCard" mb={8}>
              <Box display="flex">
                <Avatar alt={(project as any).user.name} src={(project as any).user.avatar} {...stringAvatar((project as any)?.user.name)} />
                <Box ml={3}>
                  <Typography variant='h5'>{(project as any).user.name}</Typography>
                  <Typography variant='body1'>{(project as any).user.title}</Typography>
                  {user.id !== project.user_id && user.id !== project.approve_id &&
                    <Stack direction="row" spacing={4} mt={1} className='infoBox'>
                      {(project as any).is_submitted === true &&
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <VerifiedSharpIcon sx={{ color: Colors.success, fontSize: '20px' }} />
                          <Typography variant='body1'>Submitted</Typography>
                        </Stack>
                      }
                      {(project as any).is_submitted === false && moment().unix() > moment(project?.ended_at).unix() &&
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <NewReleasesRoundedIcon sx={{ color: Colors.danger, fontSize: '20px' }} />
                          <Typography variant='body1'>Missed</Typography>
                        </Stack>
                      }
                      {(project as any).is_submitted === false && moment().unix() < moment(project?.ended_at).unix() &&
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <OfflineBoltIcon sx={{ color: Colors.success, fontSize: '20px' }} />
                          <Typography variant='body1'>New</Typography>
                        </Stack>
                      }
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <EventAvailableOutlinedIcon sx={{ color: Colors.secondary, fontSize: '20px' }} />
                        <Typography variant='body2'>{showDate(project.ended_at)}</Typography>
                      </Stack>
                    </Stack>
                  }
                  {(user.id === project.user_id || user.id === project.approve_id) &&
                    <Stack direction="row" spacing={4} mt={1} className='infoBox'>
                      {(project as any).status === 'Pending' &&
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <VerifiedSharpIcon sx={{ color: Colors.warning, fontSize: '20px' }} />
                          <Typography variant='body1'>In Progress</Typography>
                        </Stack>
                      }
                      {(project as any).status === 'WaitingForApprove' &&
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <VerifiedSharpIcon sx={{ color: Colors.warning, fontSize: '20px' }} />
                          <Typography variant='body1'>Waiting Approve</Typography>
                        </Stack>
                      }
                      {((project as any).status === 'Finished' || (project as any).status === 'Finised') &&
                        <Stack direction="row" alignItems="center" spacing={2}>
                        <VerifiedSharpIcon sx={{ color: Colors.success, fontSize: '20px' }} />
                          <Typography variant='body1'>Finished</Typography>
                        </Stack>
                      }
                      {(project as any).status === 'Closed' &&
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <OfflineBoltIcon sx={{ color: Colors.success, fontSize: '20px' }} />
                          <Typography variant='body1'>Closed</Typography>
                        </Stack>
                      }
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <EventAvailableOutlinedIcon sx={{ color: Colors.secondary, fontSize: '20px' }} />
                        <Typography variant='body2'>{showDate(project.ended_at)}</Typography>
                      </Stack>
                    </Stack>
                  }
                </Box>
              </Box>
              <Box mt={5}>
                <Typography variant='h4' mb={3}>{project.name}</Typography>
                <Typography variant='body1' mb={2}>{project.desciption.substring(0, 300)}...</Typography>
                <Link to={`/projects/${project.id ?? ''}`}>
                  <Button variant='text' size='small'>Detail</Button>
                </Link>
              </Box>
            </Box>
          })}
        </Box>
      </Box>
    </Container>
  )
}

import * as React from 'react'
import {
  CssBaseline,
  Drawer as MuiDrawer,
  Box,
  AppBar as MuiAppBar,
  type AppBarProps as MuiAppBarProps,
  Toolbar,
  List,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  CircularProgress
} from '@mui/material'
import { Link, Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone'
import GroupOutlined from '@mui/icons-material/GroupOutlined'
import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import localStorage from '../common/localStorage'
import { type User } from '../common/services'
import { useQueryClient } from '@tanstack/react-query'
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone'

const drawerWidth: number = 260

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...((open === true) && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  })
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      borderRight: '1px solid rgb(241, 243, 244)',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      boxSizing: 'border-box',
      ...((open === false) && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(12),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(13)
        }
      })
    }
  })
)

function MainContent (): JSX.Element {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(true)
  const navigate = useNavigate()
  const toggleDrawer = (): void => {
    setOpen(!open)
  }
  const queryClient = useQueryClient()
  const { pathname } = useLocation()
  const user: User = localStorage.getItem('USER')
  const logout = (): void => {
    localStorage.clear()
    queryClient.clear()
    navigate('/login')
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} color='default'>
        <Toolbar
          sx={{
            pr: '24px'
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              mr: 4,
              ml: 0.1
            }}
          >
            <MenuOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: [1]
          }}
        >
          {open && <img src={require('../assets/images/Panasonic_logo.png')} alt='logo' height={26} />}
        </Toolbar>
        <List component="nav">
          {open && <ListSubheader component="div">
            {t('Menu')}
          </ListSubheader>}
          <ListItemButton component={Link} to="/" selected={matchPath({ path: '/projects/*' }, pathname) !== null}>
            <ListItemIcon>
              <BallotTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary={t('Projects')} />
          </ListItemButton>
          {user.role === 'ADMIN' &&
            <ListItemButton component={Link} to="/users" selected={matchPath({ path: '/users' }, pathname) !== null}>
              <ListItemIcon>
                <GroupOutlined />
              </ListItemIcon>
              <ListItemText primary={t('Users')} />
            </ListItemButton>
          }
          <ListItemButton component={Link} to="/profile">
            <ListItemIcon>
              <ManageAccountsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('My Account')} />
          </ListItemButton>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <ExitToAppTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary={t('Sign Out')} />
          </ListItemButton>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Toolbar />
        <Container>
          <Box py={5}>
            <Outlet />
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default function Main (): JSX.Element {
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate()
  React.useEffect(() => {
    const loginStore = localStorage.getItem('LOGIN')
    if (loginStore === null) {
      navigate('/login')
    } else {
      setLoading(false)
    }
  }, [])
  if (loading) {
    return <Box display="flex" justifyContent="center" py={150}><CircularProgress /></Box>
  }
  return <MainContent />
}

import { Box, Button, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { ModalContent } from '../../../common/components'
import { nl2br } from '../../../common/helper'
import React from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

interface MarkingModalProps {
  open: boolean
  row: any
  onClose: (values?: {
    point: number
    point_raw: string
  }) => void
}
const getTotalPoint = (rules: any[]): string => {
  let sumValues = 0
  rules.forEach((a) => {
    sumValues += (a.weight * a.subWeight * Number(a.point ?? 0) / 10000)
  })
  return sumValues.toFixed(2)
}

export default function MarkingModal ({ open, row, onClose }: MarkingModalProps): JSX.Element {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { user, auto_point } = row
  const MARKING_RULES = [
    {
      title: 'Detail media spit with budget allocation',
      description:
        'Lowest quotations (L): 100 pts\nL + 5% ~95 pts\nL +10%: 90 pts\nL +15%: 85 pts\nL +20%: 80 pts\nL+25%: 75 pts\nL+30%: 70 pts\nL+ 35%: 65pts\nL + 40%: 60 pts\nL + 45%: 55 pts\nL+ 50%:50 pts\nSubtract 5 points for each 5% gap',
      point: auto_point,
      subWeight: 40,
      weight: 100
    },
    {
      title: 'Background and area of business',
      description:
        'Founded below 1 year: 50 points\nFounded from 2-5 years: 80 points\nFounded more than 5 years: 100 points',
      point: '',
      subWeight: 60,
      weight: 5
    },
    {
      title: 'Track record of project management for similar project',
      description:
        'Had more than 10 same projects: 100 points\n5-10 projects: 80 points\nBelow 5 projects: 50 points',
      point: '',
      subWeight: 60,
      weight: 5
    },
    {
      title: 'Invest in background research',
      description:
        'Invested for market, industry, and company background research: 80 -100 points \nInvested for market, industry, and company background research but not very detailed: 50-80 points \nNo or little background research: below 0-50 points',
      point: '',
      subWeight: 60,
      weight: 5
    },
    {
      title: 'State clearly the objective and rationale behind ',
      description:
        'Clear objective with rationale: 50-100 points\nHas objective but not rationale: 0-50 points',
      point: '',
      subWeight: 60,
      weight: 10
    },
    {
      title: 'Detail media plan/media split ',
      description:
        'Full of details: from 50 to 100 points\nLack of detail: below 0-50 points',
      point: '',
      subWeight: 60,
      weight: 15
    },
    {
      title: 'The idea is suitable with company and product',
      description:
        'Idea is very suitable with company and products: from 80 to 100\nIdea is quite suitable with company and products: from 50 to below 80\nIdea is not suitable with company and products: below 0-50',
      point: '',
      subWeight: 60,
      weight: 20
    },
    {
      title: 'Creative idea in activities',
      description:
        'Invested for creative, unique idea and different: from 80 to 100\nInvested for creative but the idea is not unique and different: From 50 to below 80\nNo new idea: below 0-50',
      point: '',
      subWeight: 60,
      weight: 25
    },
    {
      title: 'Estimate expectected result/KPI',
      description:
        'Commit a high and achievable KPI (optimistic): 80 100 points\nCommit a low and achievable KPI (conservative): below 50-80 points\nCommit a unachievable KPI: 0 50 point',
      point: '',
      subWeight: 60,
      weight: 15
    },
    {
      title: 'Project timeline',
      description:
        'Clear, timeliness: 10\nDoes not meet deadline but acceptable: 5\nTimeline does not meet requirement: 0 ',
      point: '',
      subWeight: 100,
      weight: 100
    }
  ]
  const onSubmit = (values: any): void => {
    onClose({
      point: Number(getTotalPoint(values.rules)),
      point_raw: JSON.stringify(values.rules)
    })
  }
  return <Modal
    open={open}
    onClose={() => { onClose() }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box>
      <ModalContent onClose={() => { onClose() }} title={`Marking for ${String(user.name)}`}>
        <Formik
          initialValues={{ rules: MARKING_RULES }}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
            <Form>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={180}>Evaluation points</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell width={100}>Sub-Weight</TableCell>
                      <TableCell width={100}>Weight</TableCell>
                      <TableCell width={100}>Score</TableCell>
                      <TableCell width={100}>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.rules?.map((row, index: number) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell width={180} component="th" scope="row">
                          <b>{row.title}</b>
                        </TableCell>
                        <TableCell><div dangerouslySetInnerHTML={{ __html: nl2br(row.description) }} /></TableCell>
                        <TableCell width={100}>{row.subWeight} %</TableCell>
                        <TableCell width={100}>{row.weight} %</TableCell>
                        <TableCell width={100}>
                          <TextField value={row.point} onChange={(e) => { setFieldValue(`rules[${index}].point`, e.target.value) }} size='small' variant="outlined" />
                        </TableCell>
                        <TableCell width={100}>{(row.weight * row.subWeight * Number(row.point ?? 0) / 10000).toFixed(2)}</TableCell>
                      </TableRow>
                    ))}

                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell width={180} component="th" scope="row">
                        <b>{t('Total')}</b>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell width={100}></TableCell>
                      <TableCell width={100}>

                      </TableCell>
                      <TableCell width={100}>{getTotalPoint(values.rules)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="end" gap={4} mt={5}>
                <Button onClick={() => { onClose() }}>Cancel</Button>
                <Button variant="contained" onClick={() => { handleSubmit() }}>Save</Button>
              </Box>

            </Form>)}
        </Formik>
      </ModalContent>
    </Box>
  </Modal>
}

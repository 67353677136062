import React from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function Login (): JSX.Element {
  const { t } = useTranslation()
  return (
    <Box className="authWrapper">
      <Box className="authBox" p={4}>
        <img src={require('../../assets/images/Panasonic_logo.png')} alt='logo' height={26} style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto', marginBottom: 16 }} />
        <Typography align='center'>{t('Enter your account email address')}</Typography>
        <Box mt={4}>
          <TextField label={t('Email')} size='small' variant="outlined" fullWidth />
        </Box>
        <Box mt={4}>
          <Button variant="contained" fullWidth>Forgot password</Button>
        </Box>
        <Box mt={4} sx={{ textAlign: 'center' }}>
          <Typography>{t('Do have an account?')}</Typography>
          <Typography component={Link} to="/login"> {t('Back to login')}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

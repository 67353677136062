import { emphasize, styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'

const Breadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.grey[100]
  return {
    backgroundColor,
    height: theme.spacing(7),
    color: theme.palette.text.primary,
    fontSize: 13,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06)
    }
  }
}) as typeof Chip
export default Breadcrumb

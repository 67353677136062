import { Box, Breadcrumbs, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Breadcrumb } from '../../common/components'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { type User } from '../../common/services'
import { Form, Formik } from 'formik'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import React, { useState } from 'react'

export default function ProjectForm (): JSX.Element {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const { data, isLoading } = useQuery(['/users', id], async () => {
    const { data } = await axios.get(`/users/${id ?? 0}`)
    return data as User
  }, { enabled: (id != null) })
  const onSubmit = async (values: User): Promise<void> => {
    setIsSubmiting(true)
    try {
      await axios.put(`/users/${id ?? ''}`, values)
      navigate('/users')
    } catch (ex: any) {
      alert(ex.message)
    } finally {
      setIsSubmiting(false)
    }
  }
  return <Box>
    <Typography variant='h1' mb={4}>{t('Update user')}</Typography>
    <Breadcrumbs aria-label="breadcrumb">
      <Breadcrumb
        component='div'
        label={t('Home')}
      />
      <Breadcrumb
        component='div'
        label={t('Update user')}
      />
    </Breadcrumbs>
    {!isLoading && (data != null) &&
      <Formik
        initialValues={data}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
          <Form>
            <Box mt={4} p={4} className="main">
              <Typography variant='body1'>{t('Update user information')}</Typography>
              <Grid container mt={1} spacing={6}>
                <Grid item xs={4}>
                  <TextField value={values.name} onChange={handleChange('name')} label={t('Name')} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.title} onChange={handleChange('title')} label="Title" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.email} onChange={handleChange('email')} label={t('Email')} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.phone} onChange={handleChange('phone')} label="Phone" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>Additional information</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.address ?? ''} onChange={handleChange('address')} label={t('Address')} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.tax_code ?? ''} onChange={handleChange('tax_code')} label="Tax code" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.description ?? ''} onChange={handleChange('description')} label="Description" variant="outlined" fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body1'>Status & Role</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Select value={values.is_approved}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue('is_approved', event.target.value)
                    }}>
                    <MenuItem value={0}>
                      Un-approved
                    </MenuItem>
                    <MenuItem value={1}>
                      Approved
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Select value={values.role}
                    fullWidth
                    onChange={(event) => {
                      setFieldValue('role', event.target.value)
                    }}>
                    <MenuItem value={'VENDOR'}>
                      VENDOR
                    </MenuItem>
                    <MenuItem value={'ADMIN'}>
                      ADMIN
                    </MenuItem>
                    <MenuItem value={'MANAGER'}>
                      MANAGER
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={4} display="flex" gap={4}>
                    <Button disabled={isSubmiting} variant="contained" onClick={() => { handleSubmit() }}>Update user</Button>
                    <Link to="/users">
                      <Button>Back to list</Button>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>

          </Form>)}
      </Formik>
    }
  </Box>
}

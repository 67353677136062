import * as React from 'react'
import { Box, Breadcrumbs, InputAdornment, TextField, Typography } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid'
import { Breadcrumb, StyledDataGrid } from '../../common/components'
import { Link } from 'react-router-dom'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { type User, UserApi } from '../../common/services'
import { showDate } from '../../common/time'

export default function Projects (): JSX.Element {
  const { t } = useTranslation()
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10
  })
  const { data, isLoading, isFetching } = useQuery(['/users'], async () => {
    const { data } = await new UserApi().getUsers()
    return data as any
  })
  return (
    <Box>
      <Typography variant='h1' mb={4}>{t('Users')}</Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Breadcrumb
          component='div'
          label={t('Home')}
        />
        <Breadcrumb
          component='div'
          label={t('Users')}
        />
      </Breadcrumbs>
      <Box sx={{ mt: 4 }} className="main">
        <Box px={3} pt={4} mb={2} display="flex" justifyContent="space-between">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )
            }}
            variant="outlined"
            placeholder={t('Search') ?? ''}
            size='small'
          />
        </Box>
        <Box>
          <StyledDataGrid
            autoHeight
            loading={isLoading || isFetching}
            paginationModel={paginationModel}
            rows={data?.data ?? []}
            rowCount={data?.length ?? 0}
            columns={columns}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel} />
        </Box>
      </Box>
    </Box>
  )
}

const columns: Array<GridColDef<User>> = [
  {
    field: 'id',
    headerName: 'No.',
    width: 80
  },
  {
    field: 'name',
    headerName: 'Project name',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row }) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return <Box>
        <Link title={row.name ?? ''} to={`/users/${row.id?.toString() ?? ''}`}>{row.name}</Link>
        <Typography>{row.title}</Typography>
      </Box>
    }
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 200,
    flex: 1
  },
  {
    field: 'phone',
    headerName: 'Phone',
    minWidth: 200,
    flex: 1
  },

  {
    field: 'role',
    headerName: 'Role',
    minWidth: 100,
    flex: 1
  },
  {
    field: 'is_approved',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    valueGetter: ({ row }) => {
      return row.is_approved === 1 ? 'Approved' : 'Un-approved'
    }
  },
  {
    field: 'created_at',
    headerName: 'Created Date',
    minWidth: 200,
    flex: 1,
    valueGetter: ({ row }) => {
      return (row.created_at != null) ? showDate(row.created_at) : '-'
    }
  }
]

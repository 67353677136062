import React from 'react'
import {
  DataGrid, type DataGridProps
} from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color: theme.palette.text.primary,
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '--unstable_DataGrid-headWeight': 600,
  '& .MuiDataGrid-withBorderColor': {
    borderColor: theme.palette.divider
  },
  '& .MuiDataGrid-cell': {
    paddingLeft: 12,
    paddingRight: 12
  },
  '& .MuiDataGrid-footerContainer': {
    border: 0
  }
}))

export default function DataGridCustom ({ columns, rows, ...otherProps }: DataGridProps): JSX.Element {
  return <StyledDataGrid initialState={{
    pagination: { paginationModel: { pageSize: 20 } }
  }} columns={columns} rows={rows} rowSelection={false} disableColumnMenu={true} {...otherProps} />
}

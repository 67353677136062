import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { type User } from '../../common/services'
import { Form, Formik } from 'formik'
import axios from 'axios'
import React, { useState } from 'react'
import localStorage from '../../common/localStorage'
import { toast } from 'react-toastify'
interface UserForm extends User {
  new_password?: string
  confirm_new_password?: string
}

export default function UserProfile (): JSX.Element {
  const { t } = useTranslation()
  const [isSubmiting, setIsSubmiting] = useState(false)
  const user: UserForm = localStorage.getItem('USER')
  const onSubmit = async (values: UserForm): Promise<void> => {
    setIsSubmiting(true)
    try {
      if (values?.password !== null && values?.password !== '' && values?.password !== undefined) {
        await axios.post('/checkPassword', {
          email: user.email,
          password: values.password
        })
      }
      if (values?.new_password !== values?.confirm_new_password) {
        alert('Error when validate you password, the confirmation password not match. Check your password and try again.')
        return
      }

      try {
        await axios.put(`/users/${user?.id}`, values)
        toast(t('Congratulations! You\'ve successfully updated your profile.'), {
          type: 'success'
        })
      } catch (ex: any) {
        alert(ex.message)
      } finally {
        setIsSubmiting(false)
      }
    } catch (ex) {
      alert('Your old password not correct.')
    } finally {
      setIsSubmiting(false)
    }
  }
  return <Box>
    <Typography variant='h1' mb={4}>{t('Profile')}</Typography>
    {(user != null) &&
      <Formik
        initialValues={user}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
          <Form>
            <Box mt={4} p={4} className="main">
              <Typography variant='body1'>{t('Update user information')}</Typography>
              <Grid container mt={1} spacing={6}>
                <Grid item xs={6}>
                  <TextField value={values.name} onChange={handleChange('name')} label={t('Name')} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField value={values.title} onChange={handleChange('title')} label="Title" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField value={values.email} InputProps={{ readOnly: true }} label={t('Email')} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField value={values.phone} onChange={handleChange('phone')} label="Phone" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>Additional information</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.address ?? ''} onChange={handleChange('address')} label={t('Address')} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.tax_code ?? ''} onChange={handleChange('tax_code')} label="Tax code" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.description ?? ''} onChange={handleChange('description')} label="Description" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1'>Change password</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.password ?? ''} type='password' onChange={handleChange('password')} label={t('Old password')} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.new_password ?? ''} type='password' onChange={handleChange('new_password')} label="New Password" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField value={values.confirm_new_password ?? ''} type='password' onChange={handleChange('confirm_new_password')} label="Confirm New Password" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Box mt={4} display="flex" gap={4}>
                    <Button disabled={isSubmiting} variant="contained" onClick={() => { handleSubmit() }}>Update profile</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Form>)}
      </Formik>
    }
  </Box>
}

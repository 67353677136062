import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import { ModalContent } from '../../../common/components'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import React from 'react'

interface WinningModalProps {
  open: boolean
  user: any
  onClose: (values?: {
    content: string
  }) => void
}

export default function WinningModal ({ open, user, onClose }: WinningModalProps): JSX.Element {
  const { t } = useTranslation()
  const onSubmit = (values: any): void => {
    onClose(values)
  }
  return <Modal
    open={open}
    onClose={() => { onClose() }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box>
      <ModalContent onClose={() => { onClose() }} title={`Submit win for ${String(user.name)}`}>
        <Formik
          initialValues={{ content: '' }}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
            <Form>
              <Typography>This message will be send to Approval, when they approve your status system will automatic send an email to Vendor to let they know that they have win this project.</Typography>
              <Box mt={4}>
                <TextField value={values.content} onChange={handleChange('content')} multiline rows={12} label="Content" variant="outlined" fullWidth />
              </Box>
              <Box display="flex" justifyContent="end" gap={4} mt={5}>
                <Button onClick={() => { onClose() }}>{t('Cancel')}</Button>
                <Button variant="contained" onClick={() => { handleSubmit() }}>{t('Save')}</Button>
              </Box>
            </Form>)}
        </Formik>
      </ModalContent>
    </Box>
  </Modal>
}

import React from 'react'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface FormAlertProps {
  errors: any
  touched: any
}
export default function FormValiationMessages ({ errors, touched }: FormAlertProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      {Object.keys(errors).length > 0 &&
        Object.keys(touched).length > 0 && (
          <Alert severity="error" sx={{ my: 3 }}>
            {Object.keys(errors).map((key, index): JSX.Element | undefined => {
              if ((Boolean((errors)[key])) && (Boolean((touched)[key]))) { return <div key={index} className='firstUpper'>{t((errors)[key])}</div> } else { return undefined }
            })}
          </Alert>
      )}
    </>
  )
}
